import firebase from 'firebase/compat/app'

import 'firebase/compat/analytics'
import 'firebase/compat/auth'
// import 'firebase/compat/app-check'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
// import 'firebase/compat/messaging'
import 'firebase/compat/storage'
import { RELATED_PLATFORM_SITE } from 'config/app-version'

const firebaseConfig = {
  apiKey: 'AIzaSyD78RUNFZ8VNO9f9aXd6na88W76FPSk-Pg',
  appId: '1:84615630143:web:fa08fe83cc53caee00d78a',
  authDomain: 'npgcr-b0468.firebaseapp.com',
  databaseURL: 'https://npgcr-b0468.firebaseio.com',
  measurementId: 'G-G3M8DXT1MG',
  messagingSenderId: '84615630143',
  projectId: 'npgcr-b0468',
  storageBucket: 'npgcr-b0468.appspot.com',
}

// Collections
export const COLLECTION_ADVERTISEMENTS = 'advertisements'
export const COLLECTION_ADVERTISEMENTS_CAROUSEL = 'advertisements-carousel'
export const COLLECTION_ADVERTISEMENTS_SEEN_BY_USERS =
  'advertisements-seen-by-users'
export const COLLECTION_CONFIG_CASH_IN_SHARES = 'config-cash-in-shares'
export const COLLECTION_GAME_CASINO_TRANSACTIONS = 'game-casino-transactions'
export const COLLECTION_GAME_CONFIGURATION = 'game-configuration'
export const COLLECTION_GAME_TICKETS = 'game-tickets'
export const COLLECTION_GAME_TICKETS_TOTAL_BET = 'game-tickets-total-bet'
export const COLLECTION_GAME_CASH_IN_SHARES = 'game-cash-in-shares'
export const COLLECTION_GAME_TYPES = 'game-types'
export const COLLECTION_GAME_POOLS = 'game-pools'
export const COLLECTION_SHOPS = 'shops'
export const COLLECTION_SHOPS_COUPONS = 'shops-coupons'
export const COLLECTION_TRANSACTIONS = 'transactions'
export const COLLECTION_USERS = 'users'
export const COLLECTION_USER_NOTIFICATIONS = 'user-notifications'
export const COLLECTION_WINNING_NUMBERS = 'winning-numbers'

export const firebaseApp = firebase.initializeApp(
  firebaseConfig,
  RELATED_PLATFORM_SITE,
)

// Namespaces
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier

export const AnalyticsEventName = firebase.analytics.EventName

// Firebase instances
export const analytics = firebaseApp.analytics()
export const cloudFunctions = firebaseApp.functions()
export const db = firebaseApp.firestore()
export const firebaseAuth = firebaseApp.auth()

// Configure language
firebaseAuth.useDeviceLanguage()

// export async function requestFirebaseNotificationPermission(): Promise<string> {
//   try {
//     if (firebase?.messaging.isSupported()) {
//       const messaging = firebaseApp.messaging()
//       await messaging.requestPermission()
//       const token = await messaging.getToken()

//       return token
//     }
//   } catch (error) {}

//   return ''
// }

// export function onMessageListener(
//   callback: (payload: any) => void,
// ): firebase.Unsubscribe {
//   try {
//     if (firebase?.messaging.isSupported()) {
//       return firebaseApp.messaging().onMessage(callback)
//     }
//   } catch (error) {}

//   return (): void => {}
// }

// export function onTokenRefreshListener(
//   callback: (payload: any) => void,
// ): firebase.Unsubscribe {
//   if (firebase?.messaging.isSupported()) {
//     return firebaseApp.messaging().onTokenRefresh(
//       async (): Promise<void> => {
//         try {
//           if (firebase?.messaging.isSupported()) {
//             const token = await firebaseApp.messaging().getToken()
//             callback(token)
//           }
//         } catch (error) {}

//         callback('')
//       },
//     )
//   }

//   return (): void => {}
// }
